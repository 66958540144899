<template>
  <div>
    <div class="pa-5">
      <h1>Artikelomzet</h1>
    </div>
    <div class="d-flex flex-wrap">
      <div class="header-input">
        <date-picker :dense="true" :outlined="true" :filled="true" :value="fromDate" label="Datum"
          :dateFormatted="fromDateFormatted" @changeDate="changeFromDate"></date-picker>
      </div>
      <div class="header-input">
        <date-picker :dense="true" :outlined="true" :filled="true" :value="toDate" label="t/m"
          :dateFormatted="toDateFormatted" @changeDate="changeToDate"></date-picker>
      </div>
      <div class="header-input-small">
        <v-select :items="years" v-model="year" label="Jaar" outlined></v-select>
      </div>
      <div class="header-input-small">
        <v-select :items="quarters" v-model="quarter" label="Kwartaal" outlined></v-select>
      </div>
      <div class="header-input-small">
        <v-select :items="months" item-text="text" item-value="value" v-model="month" label="Maand" outlined></v-select>
      </div>
      <div class="header-input">
        <v-select :items="categories" v-model="selectedCategory" label="Categorie" outlined></v-select>
      </div>
      <div class="header-input">
        <search-item :searchString="searchItemString" @setSearchString="setSearchItemString"
          @get-results="getItemResult" @clear-input="clearItem" :outlined="true"></search-item>
      </div>
      <div class="header-input">
        <search-client :searchString="searchClientString" :noResults="false" :filled="false"
          @setSearchString="setSearchClientString" @get-results="selectClient"
          @clear-input="clearClient"></search-client>
      </div>
      <div>
        <button class="no-print" @click="fetchItemsRevenue">Verzend</button>
        <button class="no-print" @click="reset">Reset</button>
        <button class="no-print" @click="print">Afdrukken</button>
      </div>
      <div class="full-width">
        <div v-if="selectedItems.length" class="d-flex justify-space-around pa-2">
          <h1>Aantal: {{ toDouble(computedTotalAmount) }}</h1>
          <h1>Gewicht: {{ toDouble(computedTotalWeight) }}kg</h1>
          <h1>Omzet: {{ toCurrency(computedTotalRevenue) }}</h1>
        </div>
        <div v-else class="d-flex justify-space-around pa-2">
          <h1>Aantal: {{ toDouble(totalAmount) }}</h1>
          <h1>Gewicht: {{ toDouble(totalWeight) }}kg</h1>
          <h1>Omzet: {{ toCurrency(totalRevenue) }}</h1>
        </div>
      </div>
      <div class="full-width">
        <v-data-table :items="items" :headers="headers" :server-items-length="itemsLength" :options.sync="options"
          @update:options="fetchItemsRevenue" show-select item-key="_id" v-model="selectedItems">
          <template v-slot:[`item.name`]="{ item }">
            <a class="font-weight-bold" @click="selectItem(item)">{{
              item.name
              }}</a>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ toDouble(item.amount) }}
          </template>
          <template v-slot:[`item.revenue`]="{ item }">
            {{ toCurrency(item.revenue) }}
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchGET, toCurrency, toDouble } from '../js/functions'
import datePicker from '../components/datePicker.vue'
import searchItem from '../components/searchItem.vue'
import searchClient from '../components/searchClient.vue'
export default {
  name: "itemsRevenue",
  data() {
    return {
      items: [],
      year: null,
      month: null,
      quarter: null,
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      years: [
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
        2025
      ],
      quarters: [
        1, 2, 3, 4
      ],
      months: [
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maart", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Augustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      selectedCategory: "",
      selectedItem: {},
      selectedClient: {},
      searchItemString: "",
      searchClientString: "",
      headers: [
        { text: "Artikelnr", value: "_id" },
        { text: "Artikel", value: "name" },
        { text: "Aantal", value: "amount" },
        { text: "Omzet", value: "revenue" },
      ],
      itemsLength: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["_id"],
        sortDesc: [false]
      },
      totalRevenue: 0,
      totalAmount: 0,
      totalWeight: 0,
      selectedItems: []
    }
  },
  methods: {
    toDouble(val) {
      return toDouble(val)
    },
    toCurrency(val) {
      return toCurrency(val)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.fetchItemsRevenue()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.fetchItemsRevenue()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    async fetchItemsRevenue() {
      try {
        let response = await fetchGET("fetchItemsRevenue", {
          year: this.year,
          month: this.month,
          quarter: this.quarter,
          fromDate: this.fromDate,
          toDate: this.toDate,
          itemNumber: this.selectedItem.itemNumber,
          selectedCategory: this.selectedCategory,
          clientNumber: this.selectedClient.clientNumber,
          options: this.options,
        })
        console.log(response)
        this.items = response[0].paginatedResults
        this.items.forEach(item => {
          item.selected = true
        })
        this.itemsLength = response[0] && response[0].length && response[0].length.length ? response[0].length[0].length : 0
        this.totalRevenue = response[0].totalRevenue[0] ? response[0].totalRevenue[0].totalRevenue : 0
        this.totalAmount = response[0].totalAmount[0] ? response[0].totalAmount[0].totalAmount : 0
        this.totalWeight = response[0].totalWeight[0] ? parseFloat(response[0].totalWeight[0].totalWeight.$numberDecimal) : 0
      }
      catch (e) {
        console.error(e)
      }
    },
    reset() {
      this.year = null
      this.month = null
      this.quarter = null
      this.fromDate = new Date(Date.now()).toISOString().split("T")[0],
        this.toDate = new Date(Date.now()).toISOString().split("T")[0],
        this.selectedCategory = ""
      this.selectedItem = {}
      this.selectedClient = {}
      this.searchItemString = ""
      this.searchClientString = ""
    },
    print() {
      window.print()
    },
    setSearchItemString(e) {
      this.searchItemString = e
    },
    setSearchClientString(e) {
      this.searchClientString = e
    },
    getItemResult(e) {
      this.selectedItem = e
    },
    selectClient(e) {
      this.selectedClient = e
    },
    clearItem() {
      this.searchItemString = ""
      this.selectedItem = {}
    },
    clearClient() {
      this.searchClientString = ""
      this.selectedClient = {}
    },
    selectItem(item) {
      this.selectedItem.itemNumber = item._id
      this.searchItemString = item.name
      this.fetchItemsRevenue()
    }
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchItemsRevenue()
      }
    }
  },
  computed: {
    categories() {
      return this.$store.state.settings.categories
    },
    computedTotalAmount() {
      return this.selectedItems.reduce((acc, item) => {
        return acc + item.amount
      }, 0)
    },
    computedTotalRevenue() {
      return this.selectedItems.reduce((acc, item) => {
        return acc + item.revenue
      }, 0)
    },
    computedTotalWeight() {
      return this.selectedItems.reduce((acc, item) => {
        return acc + parseFloat(item.weight.$numberDecimal)
      }, 0)
    }
  },
  components: {
    datePicker,
    searchItem,
    searchClient
  }
}
</script>
<style lang="scss" scoped>
.header-input {
  padding: 0 5px;
  max-width: 300px;
}

.header-input-small {
  padding: 0 5px;
  max-width: 200px;
}

button {
  width: 100px;
  height: 50px;
  background: darkblue;
  color: white;
}

.full-width {
  width: 100%;
}
</style>